import PropTypes from "prop-types";
import React from "react";
import { Box } from "rebass/styled-components";
import { withTheme } from "styled-components";

const Section = props => {
  return (
    <Box width="100%" py={[10, 10, 11]} {...props}>
      {props.children}
    </Box>
  );
};

Section.propTypes = {
  children: PropTypes.any
};

export default withTheme(Section);
