import PropTypes from "prop-types";
import React from "react";
import { siteinfos } from "../cms/data/settings";
import Navbar, { NavbarRaw } from "./Navbar";
import Footer, { FooterRaw } from "./Footer";
import { MaintenanceContent } from "../pages/maintenance";

const Layout = ({ children, theme, raw, ...props }) => {
  raw = raw || false;
  if (raw) {
    const menusSettings = require("../cms/data/settings").menus;
    return (
      <>
        {!siteinfos.isInMaintenanceMode && (
          <NavbarRaw menusSettings={menusSettings} theme={theme} />
        )}
        {children}
      </>
    );
  }
  return (
    <>
      {(!siteinfos.isInMaintenanceMode || process.env.NODE_ENV === "development") && (
        <>
          <Navbar />
          {children}
        </>
      )}
      {siteinfos.isInMaintenanceMode && process.env.NODE_ENV !== "development" && (
        <MaintenanceContent />
      )}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
  raw: PropTypes.bool,
  theme: PropTypes.any
};

export default Layout;
