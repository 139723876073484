import React from "react";
import { Box, Heading, Button } from "rebass/styled-components";
import Link from "../components/UI/Link";
import Page from "../templates/Page";
import Markdown from "../components/UI/Markdown";
import { useStaticQuery, graphql } from "gatsby";

const MaintenanceContent = () => {
  const { markdownRemark } = useStaticQuery(
    graphql`
      query {
        markdownRemark(frontmatter: { title: { eq: "siteinfos" } }) {
          frontmatter {
            maintenanceContent
          }
        }
      }
    `
  );
  const siteinfos = markdownRemark.frontmatter || {};
  return (
    <Box p={5} pt={100}>
      <Heading py={5} textAlign="center">
        Maintenance
      </Heading>
      <Markdown textAlign="center">{siteinfos.maintenanceContent}</Markdown>
      <Box py={5} textAlign="center">
        <Link href="/">
          <Button>Retour à l&apos;accueil</Button>
        </Link>
      </Box>
    </Box>
  );
};
const MaintenancePage = () => (
  <Page title="Maintenance">
    <MaintenanceContent />
  </Page>
);

export default MaintenancePage;
export { MaintenanceContent };
