import PropTypes from "prop-types";
import { Link as GatsbyLink } from "gatsby";
import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Link as RebassLink } from "rebass/styled-components";

const Link = ({ href, external, children, activeClassName, ...props }) => {
  href = href || "";
  const isAnchor = href.startsWith("#");
  const isExternal = href.startsWith("http") || external;
  if (isAnchor) {
    return (
      <AnchorLink href={href} {...props}>
        {children}
      </AnchorLink>
    );
  } else if (!isExternal) {
    return (
      <GatsbyLink to={href} {...props} activeClassName={activeClassName}>
        {children}
      </GatsbyLink>
    );
  }
  return (
    <RebassLink href={href} {...props}>
      {children}
    </RebassLink>
  );
};

Link.propTypes = {
  activeClassName: PropTypes.any,
  children: PropTypes.any,
  external: PropTypes.any,
  href: PropTypes.any
};
export default Link;
